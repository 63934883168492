import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Space,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { clock_out } from "../redux/actions/guest";
import { RestfullService } from "../services/RestfullService";

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 468px;
  min-width: 375px;
`;

const Title = styled(Typography)`
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #393e46;
`;

const TextButton = styled(Typography)`
  font-size: 15px;
  text-align: center;
  color: white;
  letter-spacing: 0.5px;
  font-weight: bold;
`;

const InfoDate = styled(Typography)`
  font-size: 15px;
  text-align: center;
  color: #788191;
`;

const Label = styled(Typography)`
  font-size: 11px;
  text-align: left;
  color: #788191;
`;

const TextContent = styled(Typography)`
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  color: #393e46;
  margin-bottom: 5px;
`;

const InfoCard = styled(Typography)`
  font-size: 12px;
  text-align: center;
  color: #393e46;
  margin-top: 10px;
`;

const WrapperImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: fit-content;
`;

const LogoASECPLN = styled.img`
  height: 100px;
  width: 100px;
  object-fit: contain;
`;

const data = {
  name: "",
  institution: "",
  meet: "",
  description: "",
};

function InfoGuest(props) {
  const [isLoading, setLoading] = useState(false);
  const { name, institution, meet, description } = props?.guest?.data || data;
  const id_guest = props?.guest?.id_guest;

  const getDetailGuest = async () => {
    setLoading(true);
    try {
      let response = await RestfullService.getDetailGuest(id_guest);
      if (response.status === 200) {
        if (response.data.data.clock_out) {
          props.clockOut();
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClockOut = async () => {
    setLoading(true);
    try {
      let response = await RestfullService.clockOut(id_guest);
      if (response.status === 200) {
        setLoading(false);
        props.clockOut();
      }
      setLoading(false);
    } catch (error) {
      alert("Gagal, Kirim Kembali Permintaan!");
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetailGuest();
  }, []);

  return (
    <Container>
      <Card>
        <Row>
          <Col span={24}>
            <WrapperImage>
              <LogoASECPLN
                src={require("../assets/asec-pln.jpg")}
                alt="asec-pln"
              />
            </WrapperImage>
            <Title>Formulir Tamu</Title>
            <InfoDate>
              {new Date().toLocaleString("id-ID", {
                dateStyle: "full",
                timeStyle: "long",
              })}
            </InfoDate>
            <InfoCard>
              Silahkan tekan "KELUAR" setelah melakukan pertemuan dan
              meninggalkan lokasi GI/UPT PLN.
            </InfoCard>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Card style={{ marginBlock: 20 }}>
              <Label>Nama</Label>
              <TextContent>{name}</TextContent>
              <Label>Instansi</Label>
              <TextContent>{institution}</TextContent>
              <Label>Bertemu</Label>
              <TextContent>{meet}</TextContent>
              <Label>Keperluan</Label>
              <TextContent>{description}</TextContent>
            </Card>
            <Form layout="vertical" size="middle">
              <Button
                loading={isLoading}
                onClick={handleClockOut}
                block
                type="primary"
                danger
                size="large"
              >
                <TextButton>KELUAR</TextButton>
              </Button>
            </Form>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}

const mapStateToProps = ({ guest }) => {
  return { guest };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clockOut: () => dispatch(clock_out()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InfoGuest);
