import React from "react";
import { connect } from "react-redux";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import FormGuest from "./screen/form-guest";
import InfoGuest from "./screen/info-guest";

const App = ({ guest }) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            guest.id_guest ? <Navigate to="/detail" replace /> : <FormGuest />
          }
        />
        <Route
          path="/detail"
          element={guest.id_guest ? <InfoGuest /> : <Navigate to="/" replace />}
        />
      </Routes>
    </BrowserRouter>
  );
};

const mapStateToProps = ({ guest }) => {
  return { guest };
};

export default connect(mapStateToProps, null)(App);
