import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import { useForm } from "antd/es/form/Form";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { clock_in } from "../redux/actions/guest";
import { RestfullService } from "../services/RestfullService";

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  max-width: 468px;
  min-width: 375px;
`;

const Title = styled(Typography)`
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.5px;
  color: #393e46;
`;

const Subtitle = styled(Typography)`
  font-size: 15px;
  text-align: center;
  color: #393e46;
`;

const TextButton = styled(Typography)`
  font-size: 15px;
  text-align: center;
  color: white;
  letter-spacing: 0.5px;
  font-weight: bold;
`;

const AddressIndo = styled(Typography)`
  font-size: 13px;
  text-align: center;
  color: #788191;
`;

const InfoDate = styled(Typography)`
  font-size: 15px;
  text-align: center;
  color: #788191;
`;

const WrapperImage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: fit-content;
`;

const LogoASECPLN = styled.img`
  height: 100px;
  width: 100px;
  object-fit: contain;
`;

function FormGuest(props) {
  const [isLoading, setLoading] = useState(false);
  const [loadingSub, setLoadingSub] = useState(false);
  const [location, setLocation] = useState("PT PLN");
  const [address, setAddress] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [form] = useForm();

  const handleDetailSub = async (id) => {
    try {
      setLoadingSub(true);
      let response = await RestfullService.getDetailSub(id);
      if (response.status === 200) {
        setLocation(response.data.data.alias);
        setAddress(response.data.data.address);
        setLoadingSub(false);
      }
    } catch (error) {
      console.log("Gagal Mendapatkan");
    }
  };

  const handleSubmit = () => {
    form.validateFields().then(async (res) => {
      setLoading(true);
      try {
        const payload = {
          ...res,
          clock_in: new Date(),
          id_substation: searchParams.get("sub"),
        };
        let response = await RestfullService.clockIn(payload);
        if (response.status === 200) {
          setLoading(false);
          props.clockIn(response.data.data);
        }
        setLoading(false);
      } catch (error) {
        alert("Gagal, Kirim Kembali Permintaan!");
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (!searchParams.get("sub")) {
      setSearchParams({ sub: "SUB-131" });
      handleDetailSub("SUB-131");
    }

    if (searchParams.get("sub")) {
      handleDetailSub(searchParams.get("sub"));
    }
  }, []);

  return (
    <Container>
      <Card loading={loadingSub}>
        <Row>
          <Col span={24}>
            <WrapperImage>
              <LogoASECPLN
                src={require("../assets/asec-pln.jpg")}
                alt="asec-pln"
              />
            </WrapperImage>
            <Title>Formulir Tamu</Title>
            <Subtitle>Selamat Datang di {location}</Subtitle>
            {address && <AddressIndo>{address}</AddressIndo>}
            <InfoDate>
              {new Date().toLocaleString("id-ID", {
                dateStyle: "full",
                timeStyle: "long",
              })}
            </InfoDate>
          </Col>
        </Row>
        <Divider dashed />
        <Row>
          <Col span={24}>
            <Form
              requiredMark={false}
              form={form}
              onFinish={handleSubmit}
              layout="vertical"
              size="middle"
            >
              <Form.Item
                label="Nama Lengkap"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Wajib Masukan Nama",
                  },
                ]}
              >
                <Input placeholder="Masukan Nama" size="large" />
              </Form.Item>
              <Form.Item
                label="Instansi"
                name="institution"
                rules={[
                  {
                    required: true,
                    message: "Wajib Masukan Instansi",
                  },
                ]}
              >
                <Input placeholder="Masukan Instansi" size="large" />
              </Form.Item>
              <Form.Item
                label="Bertemu"
                name="meet"
                rules={[
                  {
                    required: true,
                    message: "Wajib Masukan Nama",
                  },
                ]}
              >
                <Input
                  placeholder="Masukan Nama Pejabat Berkepentingan"
                  size="large"
                />
              </Form.Item>
              <Form.Item
                label="Keperluan"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Wajib Masukan Keperluan",
                  },
                ]}
              >
                <Input.TextArea placeholder="Masukan Keperluan" size="large" />
              </Form.Item>

              <Form.Item label="Nomor Kendaraan (Jika membawa)" name="plates">
                <Input placeholder="Masukan Nomor Kendaraan" size="large" />
              </Form.Item>

              <Form.Item
                label="Dengan Janji"
                name="appointment"
                rules={[
                  {
                    required: true,
                    message: "Wajib Masukan Pilihan",
                  },
                ]}
              >
                <Select placeholder="Masukan Pilihan">
                  <Select.Option value={true}>Iya</Select.Option>
                  <Select.Option value={false}>Tidak</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  loading={isLoading}
                  htmlType="submit"
                  block
                  type="primary"
                  size="large"
                  style={{ marginTop: 15 }}
                >
                  <TextButton>KIRIM</TextButton>
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    clockIn: (payload) => dispatch(clock_in(payload)),
  };
};

export default connect(null, mapDispatchToProps)(FormGuest);
