export const clock_in = (payload) => {
  return {
    type: "SUCCESS_CLOCK_IN",
    data: payload,
  };
};

export const clock_out = () => {
  return {
    type: "SUCCESS_CLOCK_OUT",
  };
};
