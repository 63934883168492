const initialState = {
  data: {},
  id_guest: "",
  isFetching: false,
  isError: false,
};

const guest = (state = initialState, action) => {
  switch (action.type) {
    case "CLOCK_IN":
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
        data: {},
        id_guest: "",
      });
    case "SUCCESS_CLOCK_IN":
      return Object.assign({}, state, {
        data: action.data,
        id_guest: action.data.id_guest,
        isFetching: false,
        isError: false,
      });
    case "CLOCK_OUT":
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
      });
    case "SUCCESS_CLOCK_OUT":
      return Object.assign({}, state, {
        isFetching: false,
        isError: false,
        data: {},
        id_guest: "",
      });
    default:
      return state;
  }
};

export default guest;
