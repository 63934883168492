import fetch from "./FetchInterceptor";

const RestfullService = {};

RestfullService.clockIn = function (data) {
  return fetch({
    url: "/guest",
    method: "post",
    data: data,
  });
};

RestfullService.clockOut = function (id) {
  return fetch({
    url: `/guest/check-out/${id}`,
    method: "put",
  });
};

RestfullService.getDetailSub = function (id) {
  return fetch({
    url: `/guest/sub/${id}`,
    method: "get",
  });
};

RestfullService.getDetailGuest = function (id) {
  return fetch({
    url: `/guest/detail/${id}`,
    method: "get",
  });
};

export { RestfullService };
